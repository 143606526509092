import React from "react"
import { useIntl } from "react-intl"
import { Article } from "../components/Article/Article"
import { Footer } from "../components/Footer/Footer"
import { GlobalWrapper } from "../components/GlobalWrapper/GlobalWrapper"
import { Navbar } from "../components/Navbar/Navbar"
import { SelectLanguageModal } from "../components/SelectLanguageModal/SelectLanguageModal"
import { Seo } from "../components/Seo/Seo"
import { ArticleEntity } from "../entities/ArticleEntity"
import { getBlogUrl } from "../i18n/utils/getUrl"

type Props = {
  pageContext: {
    article: ArticleEntity
    language: string
    languages: any[]
    configuration: any
    langKey: string
    header: any
    footer: any
    drawer: any
    cookies: any
    theme: string
  }
}

const ArticleTemplate: React.FC<Props> = (props) => {
  const intl = useIntl()

  return (
    <GlobalWrapper>
      <Seo
        title={props.pageContext.article.meta?.title}
        description={props.pageContext.article.meta?.description}
        lang={props.pageContext.langKey}
        image={props.pageContext.article.illustration?.src?.publicURL}
        article
        langUrls={[]}
        index={props.pageContext.article.meta?.indexable}
        breadcrumbs={[
          {
            label: intl.formatMessage({ id: "blog/articles/categories/label" }),
            url: getBlogUrl(props.pageContext.langKey),
          },
          {
            label: props.pageContext.article.meta?.title,
            url: props.pageContext.article.url,
          },
        ]}
      />

      <SelectLanguageModal languages={props.pageContext.languages} />
      <Navbar langKey={props.pageContext.langKey} />
      <Article {...props.pageContext.article} />
      <Footer lang={props.pageContext.langKey} />
    </GlobalWrapper>
  )
}

export default ArticleTemplate
